<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-end flex-wrap">
        <b-form-group
          label="Sort"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-md-0 ml-2"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                ASC
              </option>
              <option :value="true">
                DESC
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Search"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 ml-2"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(name)="data">
        <div class="d-flex flex-row">
          <b-avatar
            v-if="data.item.image !== null"
            class="mr-50 d-flex"
            :src="`${data.item.image}`"
          />
          <b-avatar
            v-else
            class="mr-50 d-flex"
            variant="primary"
            :text="data.item.name | initial"
          />
          <div class="d-flex flex-column">
            <p class="m-0">
              {{ data.item.name }}
            </p>
            <small class="text-muted">
              <feather-icon
                icon="LogInIcon"
                class="text-success"
              /> {{ `${data.item.start_date}` }}
            </small>
            <small class="text-muted">
              <feather-icon
                icon="LogOutIcon"
                class="text-danger"
              /> {{ `${data.item.end_date}` }}
            </small>
          </div>
        </div>
      </template>
      <template #cell(corporate)="data">
        <b-avatar
          v-if="data.item.corporate.logo !== null"
          class="mr-50"
          :src="`${data.item.corporate.logo}`"
        />
        <b-avatar
          v-else
          class="mr-50"
          variant="primary"
          :text="data.item.corporate.name | initial"
        />
        {{ data.item.corporate.name }}
      </template>
      <template #cell(status)="data">
        <b-badge
          v-if="data.value == 'Upcoming'"
          variant="info"
        >
          Upcoming
        </b-badge>
        <b-badge
          v-else-if="data.value == 'On Going'"
          variant="success"
        >
          On Going
        </b-badge>
        <b-badge
          v-else-if="data.value == 'Done'"
          variant="danger"
        >
          Done
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="SettingsIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="gotoDetailEvent(data.item.id)">
              <feather-icon
                icon="ListIcon"
                class="mr-50"
                style="margin-top: -5px"
              />
              <span>Detail</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
  BFormGroup, BInputGroup,
  BFormSelect, BFormInput, BInputGroupAppend,
  BPagination, BTable, BAvatar, BBadge, BButton,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'corporate', label: 'Corporate', sortable: true },
        { key: 'created_by', label: 'Created By', sortable: true },
        { key: 'location', label: 'Location', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],

      status_event: 'all',
      options_status: [
        { value: 'all', text: 'All Status' },
        { value: 'On Going', text: 'On Going' },
        { value: 'Upcoming', text: 'Upcoming' },
      ],

      active_role: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    itemsFiltered() {
      let { items } = this

      if (this.status_event !== 'all') {
        items = items.filter(item => item.status === this.status_event)
      }

      return items
    },
    items() {
      const event = this.$store.state.event.log
      // this.totalRows = event.length

      return event
    },
  },
  created() {
    this.getLogEvent()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    gotoDetailEvent(id) {
      this.$router.push({ path: `/event/${id}` })
    },
    gotoEditEvent(id) {
      this.$router.push({ path: `/event/edit/${id}` })
    },

    getLogEvent() {
      this.$store.dispatch('event/loadLogEventAll', 'done')
    },
    deleteEvent(id) {
      const cid = localStorage.getItem('client_id')

      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this data?', {
          title: 'Delete confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }

            this.$axios.delete(`${cid}/event/${id}`, config)
              .then(() => {
                this.createToast('success', 'Delete event', 'Delete event success')

                this.items = []
                this.getLogEvent()
              })
          }
        })
    },
  },
}
</script>
