<template>
  <div>
    <b-row>
      <b-col
        v-if="active_role == 'corporate'"
        cols="12"
      >
        <log-event-corporate />
      </b-col>
      <b-col
        v-else
        cols="12"
      >
        <log-event-superadmin />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LogEventCorporate from './event-table/LogEventCorporate.vue'
import LogEventSuperadmin from './event-table/LogEventSuperadmin.vue'

export default {
  components: {
    BRow,
    BCol,
    LogEventCorporate,
    LogEventSuperadmin,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      active_role: null,
    }
  },
  created() {
    this.checkAuth()
  },
  mounted() {
    this.active_role = localStorage.getItem('role')
  },
  methods: {
    gotoAddEvent() {
      this.$router.push({ path: '/event/add' })
    },
  },
}
</script>
